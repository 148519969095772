import React, {
    createContext
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Buffer
} from "buffer";

const AppleMapsContext = createContext(null);

export class AppleMapsManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            error: null,
            refreshTokenIfNeeded: this.refreshTokenIfNeeded.bind(this)
        }
        this.expires = null;
        this.loading = false;
    }

    componentDidMount() {
        this.getToken();
    }

    refreshTokenIfNeeded() {
        if(!this.state.token || !this.expires) {
            this.getToken();
            return;
        }
        if(moment.unix(this.expires) < moment()) {
            this.getToken();
        } else {
            this.logTokenExpiration(this.state.token);
        }
    }

    getJTWExpireDate(token) {
        const bodyBase64 = token.split(".")[1];
        let buffer = new Buffer(bodyBase64, "base64");
        const body = JSON.parse(buffer.toString());
        return body.exp;
    }

    logTokenExpiration(token) {
        this.expires = this.getJTWExpireDate(token);
        const expirationMoment = moment.unix(this.expires)
        const expirationTimestamp = expirationMoment.format("YYYY-MM-DD HH:MM:SS");
        const diff = moment.duration(expirationMoment.diff(moment()));
        const roundedMin = diff.asMinutes().toFixed(1);
        console.log("Apple Maps Token expires at: " + expirationTimestamp + ". (In " + roundedMin + " minutes)");
    }

    getToken() {
        if(this.loading) {
            return;
        }
        this.loading = true;
        this.setState({ token: null });
        
        axios.get("/getAppleMapsToken")
            .then((response) => {
                if(response.data.valid) {
                    const token = response.data.token;

                    this.setState({ token });

                    this.logTokenExpiration(token);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    render() {
        return (
            <AppleMapsContext.Provider value={ this.state }>
                { this.props.children }
            </AppleMapsContext.Provider>
        )
    }
}

export default AppleMapsContext;
