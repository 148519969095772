import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import {
    stringify
} from "qs";
import axios from "axios";
import * as Sentry from "@sentry/react";

import {
    I18nextProvider
} from "react-i18next";
import i18n from "./i18n";

import {
    update as tweenUpdate
} from "@tweenjs/tween.js";

import App from "./App";

import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/de";

import "./scss/main.scss";

// Start Sentry if not development environment.
const development = process.env.REACT_APP_ENVIRONMENT === "development";
if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://7257b7e9162b4dcdbc13cd0bf9d1fe6a@sentry1.zsnode.com/32",
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 1.0,
    });
}

axios.defaults.transformRequest = [function (data) {
    data = stringify(data);
    return data;
}];

if(development) {
    axios.defaults.baseURL = "/app/v2/";
} else {
    const CARTRACKER_API_URL = process.env.REACT_APP_SHOPCRATE_API_URL ? process.env.REACT_APP_SHOPCRATE_API_URL : "api.cartrackerapp.nl";
    axios.defaults.baseURL = "https://" + CARTRACKER_API_URL + "/app/v2/";
}
axios.defaults.withCredentials = true;

function animate(time) {
    requestAnimationFrame(animate);
    tweenUpdate(time)
}
requestAnimationFrame(animate);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
